import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  Divider,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api } from "../../Api";
import "./payment.css";
import "./invoice.css";

// Sample data (you might want to fetch this from an API)
const sampleData = {
  invoiceNumber: "INV-123456",
  date: "September 10, 2024",
  company: {
    name: "Cluematrix Technologies Pvt.Ltd",
    address: "Vaishnavi Nagar, Nagpur, India",
    phone: "+ 91 8999610381",
    email: "info@cluematrix.com",
  },
  client: {
    name: "John Doe",
    batch: "Java Fullstack 1",
    email: "john.doe@example.com",
  },
  items: [
    {
      description: "1st Installment Amount",
      status: "Paid",
      mode: "cash",
    },
  ],
};

// Create table rows
function createData(description, status) {
  return { description, status };
}

// Data rows for table
const rows = sampleData.items.map((item) => createData(item.description, item.status));

const Invoice = () => {
  const { invoiceNumber, date, company, client } = sampleData;
  const { rid, iid, id, name } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [student, setStudent] = useState(null);
  const [paidamt, setPaidamt] = useState(null);
  const [paidamttotal, setPaidamttotal] = useState(null);

  const downloadPDF = () => {
    const input = document.getElementById("invoice");
    html2canvas(input, {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice.pdf");
    });
  };

  const getStudentData = async (id, name) => {
    try {
      const res = await axios.get(`${api}/newskill/getById?id=${id}`);
      if (res.status === 200) {
        setStudent(res.data);
        setLoading(false);
      } else {
        console.warn("Unexpected response status:", res.status);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message || e);
      setLoading(false);
    }
  };

  const getpaid = async (iid, rid) => {
    try {
      const res = await axios.get(api + `/newskill/getDataById?id=${iid}&rid=${rid}`);
      setPaidamt(res.data);
    } catch (err) {
      console.error("Error fetching student data:", err);
    }
  };

  const getTotalpaid = async (id) => {
    try {
      const res = await axios.get(api + `/newskill/total-paid-by-student?studentId=${id}`);
      setPaidamttotal(res.data);
    } catch (err) {
      console.error("Error fetching student data:", err);
    }
  };

  React.useEffect(() => {
    getStudentData(id);
    getpaid(iid, rid);
    getTotalpaid(id);
  }, [rid, iid, id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container
        className="invoice-background"
        id="invoice"
        sx={{ padding: 4, border: "1px solid green", backgroundColor: "rgba(255, 255, 255, 0.8)" }}
      >
        <Paper sx={{ padding: 3, mb: 3, backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Payment Receipt
          </Typography>
          <Box id="logo"></Box>
          <Typography variant="h6">Receipt Number: Clue/2023-24/100{id}</Typography>
          <Typography variant="h6">Date: {paidamt ? paidamt.localDate : "Loading..."}</Typography>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h6">Company Details</Typography>
              <Typography>{company.name}</Typography>
              <Typography>{company.address}</Typography>
              <Typography>{company.phone}</Typography>
              <Typography>{company.email}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Student Details</Typography>
              <Typography>Name : {student?.name || "Loading..."}</Typography>
              <Typography>Batch :{name}</Typography>
              <Typography>Email: {student?.email || "Loading..."}</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <TableContainer component={Paper}>
            <Table aria-label="invoice table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" align="left">
                      {row.description}
                    </TableCell>
                    <TableCell align="left">
                      <b>Status:</b> &nbsp;{row.status}
                    </TableCell>
                    <TableCell align="left">
                      <b>Mode:</b> &nbsp;{paidamt ? paidamt.mode : "Loading..."}
                    </TableCell>
                    <TableCell align="left">
                      <b>Paid: </b> &nbsp;{paidamt ? paidamt.paid : "Loading..."}
                    </TableCell>
                    <TableCell align="left">
                      <b>Total paid:</b>&nbsp;
                      {paidamt !== null
                        ? paidamt.instId === 2
                          ? paidamt.tpaid + 8000
                          : paidamt.instId === 3
                          ? paidamt.tpaid + 14000
                          : paidamt.tpaid
                        : "Loading..."}
                    </TableCell>

                    <TableCell align="left">
                      <b>Balance:</b>&nbsp;
                      {paidamt !== null
                        ? 20000 -
                          (paidamt.instId === 2
                            ? paidamt.tpaid + 8000
                            : paidamt.instId === 3
                            ? paidamt.tpaid + 14000
                            : paidamt.tpaid)
                        : "Loading..."}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider sx={{ my: 2 }} />
          <Box id="sign"></Box>
        </Paper>
      </Container>
      <center>
        {" "}
        <Button variant="contained" color="" onClick={downloadPDF} sx={{ mt: 2 }}>
          Download PDF
        </Button>
      </center>
    </DashboardLayout>
  );
};

export default Invoice;
