import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";

import {
  Container,
  TextField,
  Button,
  FormControl,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { api } from "../../Api";

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";

import AddQue from "components/Modal/AddQue";
import EditTest from "components/Modal/EditTest";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
const getOnlineTestDataApi = async () => {
  try {
    const res = await axios.get(api + "/newskill/allUsers");
    console.log("Fetched data:", res.data);

    const formattedData = res.data.students.map((student) => ({
      id: student.userId,
      name: student.name,
      email: student.email,
      mobNo: student.mobNo,
      designation: student.designation || "Student",
      role: student.role,
      createdDate: student.createdDate,
      gender: student.gender || "N/A", // Default to "N/A" if null
      dob: student.dob ? new Date(student.dob).toLocaleDateString("en-US") : "N/A", // Default to "N/A" if null
      address: student.address || "N/A", // Default to "N/A" if null
      enabled: student.enabled,
    }));

    return formattedData;
  } catch (err) {
    console.error("Error fetching data:", err);
    throw err;
  }
};

function AddUsers() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    dob: "",
    mobNo: "",
    gender: "",
    role: "",
    designation: "",
    batch: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    address: "",
    dob: "",
    mobNo: "",
    gender: "",
    role: "",
    designation: "",
    batch: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = () => {
    let errors = {};
    if (!formData.testName) errors.testName = "testName is required";
    if (!formData.total_mark) errors.total_mark = "total_mark is required";
    if (!formData.total_que) errors.total_que = "total_que is required";
    if (!formData.time) errors.time = "time is required";
    if (!formData.cut_off) errors.cut_off = "cut_off is required";
    if (!formData.mark_per_que) errors.mark_per_que = "mark_per_que is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      Swal.fire({
        title: "Submitting...",
        text: "Please wait while we process your request.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let response;

      // Check the role and determine which API to hit
      if (formData.role === "manager") {
        response = await axios.post(api + "/newskill/addManager", formData);
      } else if (formData.role === "tutor") {
        response = await axios.post(
          api + `/newskill/createTutor?batchIds=${formData.batch}`,
          formData
        );
      } else if (formData.role === "student") {
        response = await axios.post(api + "/newskill/createTraineeStudent", formData);
      } else {
        console.warn("Role not supported or undefined");

        return;
      }
      Swal.close();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "User Created Successfully.",
      });
      TestList();
    } catch (error) {
      console.error("There was an error submitting the form", error);
      // Handle error, e.g., show an error message
    }
  };

  //   ---------------------------------------------------------------------------

  const [data, setData] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [batches, setBatches] = useState([]);
  useEffect(() => {
    TestList();
    getBatches();
  }, []);

  const TestList = () => {
    getOnlineTestDataApi()
      .then((formattedData) => {
        setData(formattedData);
        console.log("Formatted data:", formattedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleToggleAddModal = (id) => {
    setSelectedRowId(id);
    setIsAddModalOpen(!isAddModalOpen);
  };

  const handleToggleEditModal = (id) => {
    const rowData = data.find((row) => row.id === id);
    setEditData(rowData);
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(api + `/newskill/deleteStudent?studentId=${id}`);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "User Deleted  Successfully.",
      });
      const updatedData = data.filter((row) => row.id !== id);
      setData(updatedData);
    } catch (error) {
      console.error("Error deleting test:", error);
    }
  };
  const handleDeActivate = async (id, role) => {
    if (role == "TUTOR") {
      try {
        await axios.put(api + `/newskill/deactivateTutor?tutorId=${id}`);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Tutor De-Activated  Successfully.",
        });
        const updatedData = data.filter((row) => row.id !== id);
        setData(updatedData);
      } catch (error) {
        console.error("Error deleting test:", error);
      }
    } else {
      try {
        await axios.put(api + `/newskill/deactivate?userId=${id}`);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "User De-Activated  Successfully.",
        });
        const updatedData = data.filter((row) => row.id !== id);
        setData(updatedData);
      } catch (error) {
        console.error("Error deleting test:", error);
      }
    }
  };

  const getBatches = async () => {
    axios
      .get(api + "/newskill/allBatches")
      .then((res) => {
        setBatches(res.data.students);
        console.log("Fetched data:", res.data.courses);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const handleSave = (updatedRow) => {
    const updatedData = data.map((row) => (row.id === updatedRow.id ? updatedRow : row));
    setData(updatedData);
    setIsEditModalOpen(false);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "address", headerName: "Address", width: 250 },
    { field: "designation", headerName: "Designation", width: 100 },
    { field: "dob", headerName: "DOB", width: 120 },
    { field: "gender", headerName: "Gender", width: 70 },
    { field: "mobNo", headerName: "Mobile", width: 150 },
    { field: "role", headerName: "Role", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleToggleEditModal(params.row.id)}
            style={{ marginRight: 8 }}
          >
            Edit
          </Button>
          <Button variant="contained" color="error" onClick={() => handleDelete(params.row.id)}>
            Delete
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeActivate(params.row.id, params.row.role)}
          >
            De-Activate
          </Button>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Container maxWidth="sm">
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Create Users
            </Typography>
            <hr />
            <br />
            <form onSubmit={handleSubmit} noValidate>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Full Name</FormLabel>
                  <TextField
                    label="Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={Boolean(formErrors.name)}
                    helperText={formErrors.name}
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Email</FormLabel>
                  <TextField
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={Boolean(formErrors.email)}
                    helperText={formErrors.email}
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Address</FormLabel>
                  <TextField
                    label="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    error={Boolean(formErrors.address)}
                    helperText={formErrors.address}
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">DOB</FormLabel>
                  <TextField
                    name="dob"
                    type="date"
                    value={formData.dob}
                    onChange={handleChange}
                    error={Boolean(formErrors.dob)}
                    helperText={formErrors.dob}
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Mobile</FormLabel>
                  <TextField
                    label="Mobile"
                    name="mobNo"
                    type="number"
                    value={formData.mobNo}
                    onChange={handleChange}
                    error={Boolean(formErrors.mobNo)}
                    helperText={formErrors.mobNo}
                    fullWidth
                  />
                </FormControl>
                <FormControl component="fieldset" sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup name="gender" value={formData.gender} onChange={handleChange} row>
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                  </RadioGroup>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Role</FormLabel>
                  <Select
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    error={Boolean(formErrors.role)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Select Role
                    </MenuItem>
                    <MenuItem value="manager">Manager</MenuItem>
                    <MenuItem value="tutor">Tutor</MenuItem>
                    <MenuItem value="support">Support</MenuItem>
                    <MenuItem value="student">Student</MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend"> Select Batch</FormLabel>
                  <Select
                    name="batch"
                    value={formData.batch}
                    onChange={handleChange}
                    error={Boolean(formErrors.batch)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Select Batch
                    </MenuItem>
                    {batches.map((batch) => (
                      <MenuItem key={batch.batchId} value={batch.batchId}>
                        {batch.batchName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Designation</FormLabel>
                  <TextField
                    name="designation"
                    type="designation"
                    value={formData.designation}
                    onChange={handleChange}
                    error={Boolean(formErrors.designation)}
                    helperText={formErrors.designation}
                    fullWidth
                  />
                </FormControl>
                <Button type="submit" variant="contained" color="success" sx={{ minWidth: 100 }}>
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </MDBox>
      <div style={{ height: 400, width: "100%" }}>
        {data.length > 0 ? (
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            getRowId={(row) => row.id}
          />
        ) : (
          <div style={{ display: "block", position: "relative", left: "50%", top: "50%" }}>
            <CircularProgress />
          </div>
        )}

        {isAddModalOpen && (
          <AddQue selectedRowId={selectedRowId} handleClose={() => setIsAddModalOpen(false)} />
        )}

        {isEditModalOpen && editData && (
          <EditTest
            editData={editData}
            handleClose={() => setIsEditModalOpen(false)}
            handleSave={handleSave}
            getOnlineTestDataApi={TestList}
          />
        )}
      </div>
    </DashboardLayout>
  );
}

export default AddUsers;
