import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import AddQue from "components/Modal/AddQue";
import EditTest from "components/Modal/EditTest";
import { Button, CircularProgress } from "@mui/material";
import { api } from "../../Api";

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const getOnlineTestDataApi = async () => {
  try {
    const res = await axios.get(api + "/newskill/getTest");
    console.log("Fetched data:", res.data);
    const formattedData = res.data.Test.map((row) => ({
      ...row,
      id: row.tesId, // Ensure each row has a unique 'id' field
      creation_date: formatDate(row.creation_date),
      expiration_date: row.expiration_date ? formatDate(row.expiration_date) : "N/A",
    }));
    return formattedData;
  } catch (err) {
    console.error("Error fetching data:", err);
    throw err;
  }
};

const OnlineTestList = () => {
  const [data, setData] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    TestList();
  }, []);

  const TestList = () => {
    getOnlineTestDataApi()
      .then((formattedData) => {
        setData(formattedData);
        console.log("Formatted data:", formattedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleToggleAddModal = (id) => {
    setSelectedRowId(id);
    setIsAddModalOpen(!isAddModalOpen);
  };

  const handleToggleEditModal = (id) => {
    const rowData = data.find((row) => row.id === id);
    setEditData(rowData);
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(api + `/newskill/deleteTest?testId=${id}`);
      alert("Test Deleted Successfully");
      const updatedData = data.filter((row) => row.id !== id);
      setData(updatedData);
    } catch (error) {
      console.error("Error deleting test:", error);
    }
  };

  const handleSave = (updatedRow) => {
    const updatedData = data.map((row) => (row.id === updatedRow.id ? updatedRow : row));
    setData(updatedData);
    setIsEditModalOpen(false);
  };

  const columns = [
    { field: "tesId", headerName: "ID", width: 70 },
    { field: "testName", headerName: "Name", width: 100 },
    // { field: "userId", headerName: "User Id", width: 70 },
    { field: "total_mark", headerName: "Total Marks", width: 100 },
    { field: "total_que", headerName: "Total Que", width: 100 },
    { field: "time", headerName: "Time", width: 70 },
    { field: "cut_off", headerName: "Cut Off", width: 70 },
    { field: "mark_per_que", headerName: "Marks/Que", width: 100 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "creation_date", headerName: "Creation Date", width: 150 },
    { field: "expiration_date", headerName: "Expiration Date", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleToggleAddModal(params.row.id)}
            style={{ marginRight: 8 }}
          >
            Add Que
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleToggleEditModal(params.row.id)}
            style={{ marginRight: 8 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(params.row.id)}
            style={{ marginRight: 8 }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      {data.length > 0 ? (
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 25]}
          pagination
          getRowId={(row) => row.id}
        />
      ) : (
        <div style={{ display: "block", position: "relative", left: "50%", top: "50%" }}>
          <CircularProgress />
        </div>
      )}

      {isAddModalOpen && (
        <AddQue selectedRowId={selectedRowId} handleClose={() => setIsAddModalOpen(false)} />
      )}

      {isEditModalOpen && editData && (
        <EditTest
          editData={editData}
          handleClose={() => setIsEditModalOpen(false)}
          handleSave={handleSave}
          getOnlineTestDataApi={TestList}
        />
      )}
    </div>
  );
};

export default OnlineTestList;
