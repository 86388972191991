import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid"; // Assuming you're using DataGrid from Material-UI
import axios from "axios";
import { Switch } from "@mui/material";
import { api } from "../../Api";
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const handleToggle = async (id, newStatus) => {
  try {
    // Make API call to update the status
    await axios.put(api + `/newskill/updateCollege?college_id=${id}`, {
      id,
      status: newStatus,
    });
    // Update the state to reflect the new status
    setData((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, status: newStatus } : row))
    );
  } catch (error) {
    console.log("Error updating status:", error);
  }
};
const getCollegeDataApi = async () => {
  try {
    const res = await axios.get(api + "/newskill/getAllCollege");
    console.log(res.data); // Check the structure of the response

    // Check if res.data.allColleges is defined and is an array
    if (res.data && Array.isArray(res.data.allColleges)) {
      const formattedData = res.data.allColleges.map((row) => ({
        id: row[0],
        username: row[1],
        contact_no: row[2],
        balance: row[4],
        validity: formatDate(row[6]),
        email: row[5],
        creationDate: formatDate(row[6]),
        package_name: row[7],
        status: row[8] || false, // assuming there's a status field or default to false
      }));
      return formattedData;
    } else {
      console.error("Unexpected data structure", res.data);
      return []; // Return an empty array if the structure is not as expected
    }
  } catch (err) {
    console.error("Error fetching college data:", err);
    throw err; // Rethrow the error to handle it in the calling function
  }
};

const CollegeList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getCollegeDataApi().then((formattedData) => setData(formattedData));
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "username", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "contact_no", headerName: "Contact", width: 200 },
    { field: "package_name", headerName: "Package Name", width: 200 },
    { field: "balance", headerName: "Balance", width: 200 },
    { field: "validity", headerName: "Validity", width: 200 },

    { field: "creationDate", headerName: "Creation Date", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={(e) => handleToggle(params.row.id, e.target.checked)}
        />
      ),
    },
    // Add more columns as needed
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25]}
        pagination
        getRowId={(row) => row.id} // Specify the id property for each row
      />
    </div>
  );
};

export default CollegeList;
