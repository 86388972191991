// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/images/p.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/images/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sign {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%; /* Increase the size of the background image */
    flex-direction: column;
    justify-content: right;
    align-items: end;
    display: flex;
    position: relative;
  top: 90px;
    left: 70%;
    width: 20rem;  /* Increase width */
    height: 20rem; /* Increase height */
  }
#logo {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%; /* Increase the size of the background image */
    flex-direction: column;
    justify-content: right;
    align-items: end;
    display: flex;
    position: absolute;
  top: 5px;
    left: 80%;
    width: 12rem;  /* Increase width */
    height: 12rem; /* Increase height */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TrainingPage/invoice.css"],"names":[],"mappings":"AAAA;IACI,yDAA4C;IAC5C,2BAA2B;IAC3B,4BAA4B;IAC5B,qBAAqB,EAAE,8CAA8C;IACrE,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;EACpB,SAAS;IACP,SAAS;IACT,YAAY,GAAG,mBAAmB;IAClC,aAAa,EAAE,oBAAoB;EACrC;AACF;IACI,yDAA6C;IAC7C,2BAA2B;IAC3B,4BAA4B;IAC5B,qBAAqB,EAAE,8CAA8C;IACrE,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;EACpB,QAAQ;IACN,SAAS;IACT,YAAY,GAAG,mBAAmB;IAClC,aAAa,EAAE,oBAAoB;EACrC","sourcesContent":["#sign {\n    background-image: url(\"assets/images/p.png\");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: 100%; /* Increase the size of the background image */\n    flex-direction: column;\n    justify-content: right;\n    align-items: end;\n    display: flex;\n    position: relative;\n  top: 90px;\n    left: 70%;\n    width: 20rem;  /* Increase width */\n    height: 20rem; /* Increase height */\n  }\n#logo {\n    background-image: url(\"assets/images/bg.png\");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: 100%; /* Increase the size of the background image */\n    flex-direction: column;\n    justify-content: right;\n    align-items: end;\n    display: flex;\n    position: absolute;\n  top: 5px;\n    left: 80%;\n    width: 12rem;  /* Increase width */\n    height: 12rem; /* Increase height */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
