// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";

import College from "layouts/college";
import Company from "layouts/company";
import Package from "layouts/package";
import Department from "layouts/department";
import Technology from "layouts/technology";
import OnlineTest from "layouts/onlinetest";
import Questions from "layouts/questions";
import ReportsList from "layouts/ReportsList";
import TrainingDashboard from "components/TrainingPage/TrainingDashboard";
import AddUsers from "components/TrainingPage/AddUsers";
import CreateBatch from "components/TrainingPage/CreateBatch";
import TraineeList from "components/TrainingPage/TraineeList";
import AssignBatch from "components/TrainingPage/AssignBatch";
import Payment from "components/TrainingPage/Payment";
import Invoice from "components/TrainingPage/Invoices";
import TraineeTutList from "components/TrainingPage/TraineeTutorList";
import StudyMaterial from "components/TrainingPage/StudyMaterials";
import DeactivateUser from "components/TrainingPage/DeactivateUsers";

const routes = [
  {
    route: "/",
    component: <SignIn />,
  },

  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "College List",
    key: "college",
    icon: <Icon fontSize="small">College</Icon>,
    route: "/college",
    component: <College />,
  },
  {
    type: "collapse",
    name: "Company List",
    key: "company",
    icon: <Icon fontSize="small">Company</Icon>,
    route: "/company",
    component: <Company />,
  },
  {
    type: "collapse",
    name: "Package List",
    key: "package",
    icon: <Icon fontSize="small">Package</Icon>,
    route: "/package",
    component: <Package />,
  },
  {
    type: "collapse",
    name: "Department Data",
    key: "department",
    icon: <Icon fontSize="small">Department</Icon>,
    route: "/department",
    component: <Department />,
  },
  {
    type: "collapse",
    name: "Technology Data",
    key: "technology",
    icon: <Icon fontSize="small">Technology</Icon>,
    route: "/technology",
    component: <Technology />,
  },
  {
    type: "collapse",
    name: "Online Test Data",
    key: "onlinetest",
    icon: <Icon fontSize="small">OnlineTest</Icon>,
    route: "/onlinetest",
    component: <OnlineTest />,
  },
  {
    type: "collapse",
    name: "Question Bank",
    key: "questions",
    icon: <Icon fontSize="small">Question</Icon>,
    route: "/Questions",
    component: <Questions />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">Reports</Icon>,
    route: "/Reports",
    component: <ReportsList />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Training Dashboard",
    key: "training",
    icon: <Icon fontSize="small">Training Dashboard</Icon>,
    route: "/TrainingDashboard",
    component: <TrainingDashboard />,
  },
  {
    route: "/AddUsers",
    component: <AddUsers />,
  },
  {
    route: "/CreateBatch",
    component: <CreateBatch />,
  },
  {
    route: "/AssignBatch",
    component: <AssignBatch />,
  },
  {
    route: "/TraineeList",
    component: <TraineeList />,
  },
  {
    route: "/TraineeTutorList",
    component: <TraineeTutList />,
  },
  {
    route: "/Payment",
    component: <Payment />,
  },
  {
    route: "/StudyMaterial",
    component: <StudyMaterial />,
  },
  {
    route: "/invoice/:rid/:iid/:id/:name",
    component: <Invoice />,
  },
  {
    route: "/DeactivateUsers",
    component: <DeactivateUser />,
  },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
