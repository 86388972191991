import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";
import React, { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { api } from "../../Api";
function StudyMaterial() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      sasasasasa
    </DashboardLayout>
  );
}

export default StudyMaterial;
