import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";
import React, { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { api } from "../../Api";
function TraineeList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedData = await getOnlineTestDataApi();
        setData(formattedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getOnlineTestDataApi = async () => {
    try {
      const res = await axios.get(api + "/newskill/traineeStudents");
      console.log("Fetched data:", res.data);

      const formattedData = res.data.map((student) => ({
        id: student.name.userId,
        name: student.name.name,
        email: student.name.email,
        mobNo: student.name.mobNo,
        designation: student.name.designation || student.batchname,
        role: student.name.role,
        createdDate: student.name.createdDate,
        gender: student.name.gender || "N/A",
        dob: student.name.dob ? new Date(student.name.dob).toLocaleDateString("en-US") : "N/A",
        address: student.name.address || "N/A",
        enabled: student.name.enabled,
      }));

      return formattedData;
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "address", headerName: "Address", width: 250 },
    { field: "designation", headerName: "Batch", width: 100 },
    { field: "dob", headerName: "DOB", width: 120 },
    { field: "gender", headerName: "Gender", width: 70 },
    { field: "mobNo", headerName: "Mobile", width: 150 },
    { field: "role", headerName: "Role", width: 150 },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ height: 400, width: "100%" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            getRowId={(row) => row.id}
          />
        )}
      </div>
    </DashboardLayout>
  );
}

export default TraineeList;
