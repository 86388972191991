import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import axios from "axios";
import { api } from "../../Api";
import { FormControlLabel, RadioGroup, Radio } from "@mui/material";
import RadioGroupContext from "@mui/material/RadioGroup/RadioGroupContext";

const CrudModal = styled("div")({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#dfe3eb",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  maxWidth: "90%",
  width: "400px",
  zIndex: "1000",
  "& .MuiTextField-root": {
    margin: "8px",
    width: "calc(50% - 16px)", // Adjust width here
  },
  "& .MuiButton-root": {
    margin: "8px",
  },
});
const convertToDateInputFormat = (dateStr) => {
  const [month, day, year] = dateStr.split("/").map(Number);
  return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
};
const EditTest = ({ editData, handleClose, getOnlineTestDataApi }) => {
  const [formData, setFormData] = useState(editData);
  const formattedDob = convertToDateInputFormat(formData.dob);
  useEffect(() => {
    setFormData(editData);
  }, [editData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     // Create a new object with only the specified fields
  //     const payload = {
  //       name: formData.name,

  //       mobNo: formData.mobNo,
  //       gender: formData.gender,
  //       dob: formData.dob,
  //       address: formData.address,
  //     };

  //     // Make an API call to save the form data
  //     const response = await axios.put(
  //       api + `/newskill/updateTrainee?studentId=${formData.id}`,
  //       payload
  //     );
  //     console.log("Form submitted successfully", response.data);
  //     alert("Data Updated");
  //     handleClose();
  //     getOnlineTestDataApi();
  //     // Call the handleSave function if needed
  //   } catch (error) {
  //     console.error("There was an error submitting the form", error);
  //     // Handle error, e.g., show an error message
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Convert the date to the correct format for the payload
      const formattedDob = convertToDateInputFormat(formData.dob);

      // Create a new object with only the specified fields
      const payload = {
        name: formData.name,
        mobNo: formData.mobNo,
        gender: formData.gender,
        dob: formattedDob, // Use the formatted date here
        address: formData.address,
      };

      // Make an API call to save the form data
      const response = await axios.put(
        api + `/newskill/updateTrainee?studentId=${formData.id}`,
        payload
      );
      console.log("Form submitted successfully", response.data);
      alert("Data Updated");
      handleClose();
      getOnlineTestDataApi();
    } catch (error) {
      console.error("There was an error submitting the form", error);
      // Handle error, e.g., show an error message
    }
  };

  return (
    <CrudModal>
      <div>
        <div>
          <h3>Edit User Details</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              name="userId"
              value={formData.id}
              onChange={handleChange}
              label="userId"
              variant="outlined"
              required
            />
            <TextField
              name="name"
              value={formData.name}
              onChange={handleChange}
              label="Name"
              variant="outlined"
              required
            />

            <TextField
              name="email"
              value={formData.email}
              onChange={handleChange}
              label="Email"
              variant="outlined"
              required
            />
            <TextField
              name="address"
              value={formData.address}
              onChange={handleChange}
              label="Address"
              variant="outlined"
              required
            />
            <TextField
              name="mobNo"
              value={formData.mobNo}
              onChange={handleChange}
              label="mobile"
              variant="outlined"
              required
            />
            <RadioGroup
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              row // Use row if you want horizontal alignment
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
            <TextField
              name="dob"
              value={formattedDob} // Use formatted date here
              onChange={handleChange}
              label="Date of Birth"
              variant="outlined"
              type="date" // Set the type to "date"
              required
              InputLabelProps={{
                shrink: true, // Ensure the label is always visible
              }}
            />
          </div>
          <Button type="submit" variant="contained" color="error">
            Save
          </Button>
          <Button type="button" variant="contained" color="error" onClick={handleClose}>
            Close
          </Button>
        </form>
      </div>
    </CrudModal>
  );
};

EditTest.propTypes = {
  editData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  getOnlineTestDataApi: PropTypes.func.isRequired,
};

export default EditTest;
